import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";

const UpdateDialog = ({openDialog,setOpenDialog,title,message}) => {
    const navigate = useNavigate();
    return(
        <Dialog open={openDialog} onClose={()=>{setOpenDialog(false)}}>
            
            <DialogTitle>
                {title}
            </DialogTitle>
            
            <DialogContent>
            
                <DialogContentText>
                    {message}
                </DialogContentText>

                <DialogActions>
                    <Button variant="outlined" 
                        onClick={
                            ()=>{
                                setOpenDialog(false);
                                navigate("/download")
                                }
                            }>
                        Download
                    </Button>
                </DialogActions>

            </DialogContent>
        </Dialog>
    );
}

export default UpdateDialog;