import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Container, Typography } from "@mui/material";
import ReactPlayer from "react-player";
import AppBarSimple from "../components/AppBarSimple";
import React from "react";
import { ExpandMore } from "@mui/icons-material";

export default function Tutorial () {
    const videos = [
        {
            title: "Edição de Vídeo (Parte 1)",
            url: 'https://vimeo.com/904626088'
        },{
            title: "Edição de Vídeo (Parte 2)",
            url: 'https://vimeo.com/904626866'
        },{
            title: "Análise de Partida",
            url: 'https://vimeo.com/904630581'
        },{
            title: "Análise Customizada",
            url: 'https://vimeo.com/904630516'
        },{
            title: "Funções Úteis do GameVisionPRO",
            url: 'https://vimeo.com/904631241'
        },,{
            title: "Game Vision Motion",
            url: 'https://youtu.be/PIMWfqL1WeA'
        },{
            title: "Exportação Avançada",
            url: 'https://vimeo.com/1028168550',
            desc: "Agora você pode exportar todos os trechos (ou filtrados) de cada linha do tempo!"
        },{
            title: "Otimização de Vídeos",
            url: 'https://vimeo.com/1021833373',
            desc: 'https://handbrake.fr/'
        },{
            title: "Erro da Tela Branca",
            url: 'https://vimeo.com/1025271896',
            desc: "F12 > Console > Envie um print para o Suporte."
        },{
            title: "Vídeo Travando e Elementos Fora de Posição",
            url: 'https://vimeo.com/1025271853',
            desc: "Edite o vídeo pausado, e reduza a velocidade de reprodução. Utilize as teclas 'C' e 'Z' para navegar com precisão."
        }
    ];

    const [expanded, setExpanded] = React.useState('panel0');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', }}>
			<AppBarSimple />      
            <Container component="main" sx={{mb: 8}}>   
                <Box sx={{marginTop: 12, display: 'flex',  flexDirection: 'column', alignItems: 'center',}} >
                    {/*<Alert sx={{mt:1,mb:3}} variant="outlined" severity="info">
                        A versão Beta está disponível GRÁTIS para assinantes FutebolCursos.com (instruções de acesso no Portal). Não temos planos para disponibilizar o GVP separadamente. 
                    </Alert>*/}
                    <Typography sx={{mt:2, mb:6}} variant="h4">
                        <b>Como Usar o Game Vision PRO?</b>
                    </Typography>
                    {videos.map((video,index)=>(
                        <Accordion 
                            sx={{width: '100%'}} 
                            key={index} 
                            expanded={expanded === 'panel'+index} 
                            onChange={handleChange('panel'+index)}
                            slotProps={{ transition: { unmountOnExit: true } }}
                        >
                            <AccordionSummary expandIcon={<ExpandMore />} >
                                <Typography>
                                    {video.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ReactPlayer
                                    width="100%"
                                    heigth="720px"
                                    url={video.url}
                                    playsinline
                                    controls={true}
                                />
                                <Typography variant='body'>
                                    {video.desc ? video.desc : ''}
                                </Typography>
                            </AccordionDetails>
                        </Accordion> 
                    ))}  
                </Box>

            </Container>
        </Box>
            
    );
}