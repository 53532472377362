import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import UserMenu from '../components/UserMenu';

const AppBarDashboard = () => {
	const navigate = useNavigate();
	
	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar color="default" elevation={1} position="fixed" >
				<Toolbar>
					<Typography onClick={()=>{navigate('/dashboard')}}  variant="span" component="div" sx={{ cursor:'pointer'}} color="primary">
						<b>GAME VISION PRO</b>
					</Typography>
					
					<Box sx={{ ml:2, flexGrow: 1, flexDirection: 'rows', display: 'flex', alignItems:'center' }}>
						
						<Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={()=>{navigate('/tutorial')}} >
							Tutorial
						</Button>

						{/*
						<Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={()=>{navigate('/updates')}} >
							Atualizações
						</Button>
						*/}

					</Box>

					
					{!window.electron && (
						<Button sx={{mr:2}} onClick={()=>{navigate('/download')}} variant="outlined" size="small">
							Download
						</Button>
					)}

					<UserMenu/>
					
				</Toolbar>
			</AppBar>
		</Box>
	);
}

export default AppBarDashboard;